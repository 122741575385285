import About from './components/About/About';
import Banner from './components/Banner/Banner';
import { ParallaxProvider } from 'react-scroll-parallax';
import Navbar from './components/Navbar/Navbar.jsx';
import Projects from './components/Projects/Projects';
import CallToAction from './components/BannerCallToAction/BannerCallToAction.jsx';
import ContactForm from './components/ContactForm/ContactForm.jsx';
import Skills from './components/Skills/Skills.jsx';
import Footer from './components/Footer/Footer.jsx';
import MouseEffect from './components/MouseEffect/MouseEffect.jsx';

import './App.css';

function App() {
  return (
    <div className="App">
      <MouseEffect />
      <ParallaxProvider>
        <section className="banner">
          <Banner />
          <Navbar />
          <CallToAction />
        </section>
        <About />
        <Skills />
        <Projects />
      </ParallaxProvider>
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
