import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email_from: '',
    message: '',
  });
  const [formState, setFormState] = useState('pristine');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email_from || !formData.message) {
      setFormState('invalid');
      return;
    }

    setFormState('sending');

    try {
      const response = await emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_PUBLIC_KEY,
      );

      if (response.status === 200) {
        setFormState('sent');
      } else {
        setFormState('invalid');
      }
    } catch (error) {
      setFormState('invalid');
    }

    e.target.reset();

    setFormData({
      name: '',
      email_from: '',
      message: '',
    });
  };

  let message = '';

  switch (formState) {
    case 'invalid':
      message = 'Veuillez remplir tous les champs.';
      break;
    case 'sending':
      message = 'Envoi en cours...';
      break;
    case 'sent':
      message = 'Votre message a bien été envoyé !';
      break;
    default:
      message = '';
  }

  useEffect(() => {
    if (formState === 'sent') {
      const timer = setTimeout(() => {
        setFormState('pristine');
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [formState]);

  return (
    <section className="contact">
      <h2>Me contacter</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Nom"
            value={formData.name}
            onChange={handleInputChange}
            autoComplete="off"
            required
          />
        </div>
        <div>
          <input
            type="email"
            id="email_from"
            name="email_from"
            placeholder="Adresse e-mail"
            value={formData.email_from}
            onChange={handleInputChange}
            autoComplete="off"
            required
          />
        </div>
        <div>
          <textarea
            id="message"
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit">Envoyer</button>
      </form>
      <p className={formState === 'sent' ? 'success-message' : 'error-message'}>
        {message}
      </p>
    </section>
  );
};

export default ContactForm;
