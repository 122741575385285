import './BannerCallToAction.css';

export default function BannerCallToAction() {
  const handleClick = () => {
    const aboutSection = document.querySelector('.about');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return <div className="icon-scroll" onClick={handleClick}></div>;
}
