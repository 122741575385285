import React, { useState, useEffect } from 'react';
import './MouseEffect.css';

function MouseEffect() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [mouseOnBottom, setMouseOnBottom] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY + window.scrollY });

      const bottomOfPage = document.body.scrollHeight;
      const threshold = 370;

      if (e.clientY + window.scrollY + threshold > bottomOfPage) {
        setMouseOnBottom(true);
      } else {
        setMouseOnBottom(false);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div
      className={`mouse-light ${mouseOnBottom ? 'on-bottom' : ''}`}
      style={{ top: mousePosition.y, left: mousePosition.x }}
    ></div>
  );
}

export default MouseEffect;
