import './About.css';

function About() {
  return (
    <section className="about">
      <div className="about-right">
        <h2>À propos</h2>
        <p>
          J'ai suivi le cursus de développeur d'application web sur le campus de
          Paris de la Wild Code School, et je suis actuellement inscrit sur le
          parcours de développeur web de la plateforme d'enseignement à distance
          OpenClassRooms en vue d'obtenir le titre professionnel de "Développeur
          intégrateur web".
        </p>
      </div>
    </section>
  );
}

export default About;
