import { AiFillGithub } from 'react-icons/ai';
import { AiFillLinkedin } from 'react-icons/ai';

import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className="social-links">
        <a
          href="https://github.com/johlbaum"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillGithub />
        </a>
        <a
          href="https://www.linkedin.com/in/julienohlbaum"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillLinkedin />
        </a>
      </div>
      <p>2023, Julien Ohlbaum </p>
    </div>
  );
}

export default Footer;
