import React, { useState, useEffect } from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import BgImage from '../../assets/header-bg.jpeg';
import { AiFillGithub } from 'react-icons/ai';
import { AiFillLinkedin } from 'react-icons/ai';

import './Banner.css';

function Banner() {
  const images = [BgImage];
  const [backgroundOpacity, setBackgroundOpacity] = useState(0.6);
  const [headerText, setHeaderText] = useState('');
  const [bodyText, setBodyText] = useState('');
  const headerSentence = 'Julien Ohlbaum';
  const bodySentence =
    'Développeur web spécialisé dans la création de Single Page Applications';
  const speed = 50;
  const [cursorPosition, setCursorPosition] = useState(0);
  const [headerCursorVisible, setHeaderCursorVisible] = useState(true);
  const [bodyCursorVisible, setBodyCursorVisible] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= headerSentence.length) {
        setHeaderText(headerSentence.substring(0, currentIndex));
        currentIndex++;
      } else {
        setHeaderCursorVisible(false);
        currentIndex = 0;
        clearInterval(interval);
        const bodyInterval = setInterval(() => {
          if (currentIndex <= bodySentence.length) {
            setBodyText(bodySentence.substring(0, currentIndex));
            currentIndex++;
            setBodyCursorVisible(true);
          } else {
            clearInterval(bodyInterval);
          }
        }, speed);
      }
    }, speed);
  }, []);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setCursorPosition((prevPosition) => {
        if (
          (!headerCursorVisible && !bodyCursorVisible) ||
          cursorPosition * 20 >= bodyText.length * 20
        ) {
          clearInterval(cursorInterval);
          return prevPosition;
        }
        return prevPosition + 1;
      });
    }, speed);

    return () => {
      clearInterval(cursorInterval);
    };
  }, [headerCursorVisible, bodyCursorVisible, cursorPosition, bodyText]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;
      const maxScroll = windowHeight / 2.5;

      const scrollPercentage = Math.min(
        1,
        Math.max(
          0.6,
          0.6 +
            (1 -
              Math.exp(-(scrollPosition - maxScroll / 2) / (maxScroll / 0.7))) *
              0.6,
        ),
      );

      setBackgroundOpacity(scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <ParallaxBanner
        layers={[
          {
            image: images[0],
            speed: -35,
          },
        ]}
        className="parallax-overlay"
        style={{
          '--background-opacity': backgroundOpacity,
        }}
      ></ParallaxBanner>

      <div className="content">
        <p>Bonjour, je suis</p>
        <div className="typing-animation">
          <h1>
            {headerText}
            {headerCursorVisible && (
              <span
                className="cursor"
                style={{ left: `${cursorPosition * 20}px` }}
              ></span>
            )}
          </h1>
          <p className="content-description">
            {bodyText}
            {bodyCursorVisible && (
              <span
                className="cursor"
                style={{ left: `${cursorPosition * 20}px` }}
              ></span>
            )}
          </p>
          <hr className="separation-line" />
          <div className="social-links">
            <a
              href="https://github.com/johlbaum"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillGithub />
            </a>
            <a
              href="https://www.linkedin.com/in/julienohlbaum"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillLinkedin />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
