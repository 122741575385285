import { motion } from 'framer-motion';
import { RiArrowRightUpLine } from 'react-icons/ri';

import './ProjectItem.css';

function ProjectItem({
  image,
  title,
  description,
  skills,
  link,
  altText,
  challengesAndSolutions,
}) {
  console.log('challengesAndSolutions:', challengesAndSolutions);
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="project-link"
    >
      <motion.div
        animate={{ opacity: 1, scale: 1 }}
        initial={{ opacity: 0, scale: 0 }}
        exit={{ opacity: 0, scale: 0 }}
        layout
        className="projectItem"
      >
        <div className="project-img">
          <img src={image} alt={altText} className="bgImage" />
        </div>
        <div className="project-content">
          <div className="project-content-header">
            <h3>{title}</h3>
            <RiArrowRightUpLine />
          </div>
          <p>{description}</p>
          {challengesAndSolutions !== '' ? (
            <p className="challenges-and-solutions">
              Problématique et solutions :
            </p>
          ) : (
            ''
          )}
          <p>{challengesAndSolutions}</p>
          <div className="project-content-skills">
            {skills.map((skill, index) => (
              <p key={`${skill}-${index}`}>{skill}</p>
            ))}
          </div>
        </div>
      </motion.div>
    </a>
  );
}

export default ProjectItem;
