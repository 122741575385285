import { ProjectList } from '../../helpers/ProjectsData/projectsData';
import React, { useState, useEffect } from 'react';

import './Filters.css';

const Filters = ({ setFiltered }) => {
  const [selectedFilter, setSelectedFilter] = useState('Tous');

  const filters = ['Tous', 'React', 'Node.js', 'Vanilla Js', 'Intégration'];

  useEffect(() => {
    if (selectedFilter === 'Tous') {
      setFiltered(ProjectList);
      return;
    }
    const filtered = ProjectList.filter((project) => {
      return project.mainSkill === selectedFilter;
    });
    setFiltered(filtered);

    smoothScrollToSection('.projects');
  }, [selectedFilter, setFiltered]);

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const smoothScrollToSection = (sectionSelector) => {
    const section = document.querySelector(sectionSelector);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="filter-bar">
      {filters.map((filter, index) => (
        <div
          key={`${filter}-${index}`}
          className={`filter ${selectedFilter === filter ? 'selected' : ''}`}
          onClick={() => handleFilterClick(filter)}
        >
          <div className="filter-bar-left">
            <div className="filter-bar-background"></div>
          </div>
          <div className="filter-bar-label">{filter}</div>
        </div>
      ))}
    </div>
  );
};

export default Filters;
