import './Skills.css';

function Skills() {
  return (
    <>
      <section className="skills">
        <i className="devicon-html5-plain-wordmark colored"></i>
        <i className="devicon-css3-plain-wordmark colored"></i>
        <i className="devicon-sass-original colored"></i>
        <i className="devicon-javascript-plain colored"></i>
        <i className="devicon-react-original-wordmark colored"></i>
        <i className="devicon-nodejs-plain-wordmark colored"></i>
        <i className="devicon-mongodb-plain-wordmark colored"></i>
      </section>
      <p className="skills-title">Stack MERN</p>
    </>
  );
}

export default Skills;
